html,
body {
	/* width: 100%;
	height: 100%;
	position: fixed;
	overflow: auto; */
	/* overflow-x: hidden; */
	/* background-color: #fafafa; */
	font-family: 'Proxima Nova', 'Roboto', sans-serif;
	font-size: 95%;
	/* font-size: 62.5%; 62.5% of 16px = 10px */
	/* background-color: #eff3f5; */
	/* background-color: #eef2f6 !important; */
	/* background-color: #f7f7f9 !important; */
	/* background-color: #f6f8f9 !important; */
	background-color: #f2f4f5 !important;
	margin: 0;
	padding: 0;
	line-height: 1.6em;
	/* font-family: 'Open Sans', Roboto, sans-serif; */
	/* font-size: 0.9em; */
}

html {
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

/* button:focus {
	outline: none;
	background-color: rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.7) !important;
} */

/* body {
	overflow-x: hidden;
} */

/* 
h1 {
	color: #4a4a4a;
	margin: 40px 0 40px 0;
	font-family: 'din_lightregular', 'Roboto', sans-serif;
	font-size: 1.8em;
}

h2 {
	text-transform: uppercase;
	color: #4a4a4a;
	margin: 40px 0 40px 0;
	font-family: 'din_lightregular', 'Roboto', sans-serif;
	font-size: 1.8em;
} */

h2 {
	font-family: 'din_medium', 'Roboto', sans-serif;
	font-size: 2.2em;
	font-weight: 300;
	/* font-size: 1.8em; */
	/* font-weight: 500; */
}

h3 {
	margin-top: 10px;
	margin-bottom: 10px;
	display: 'flex';
	align-items: 'center';

	font-family: 'din_medium', 'Roboto', sans-serif;
	font-size: 1.3em;
	font-weight: 500;
}

h5 {
	margin: 0px 0px 5px 0px;
}

.caption {
	text-transform: uppercase;
	font-weight: 600;
}

a {
	/*color: #FF5722;*/
	color: #016ef5;
	text-decoration: none;
	/*color: #000;*/
}

a:hover {
	color: #5ca5ff;
	text-decoration: none;
	transition: all 150ms cubic-bezier(0.29, 0.15, 0.07, 1.01);
}

.materialize-textarea {
	margin: 0;
}

.wrapper {
	overflow: hidden;
	/* display: flex; */
	min-height: 100vh;
	/* flex-direction: column; */
	/* background-color: #fafafa; */
}

/* 
.drawer > div:first-child {
	transition: width 150ms ease !important;
}

.drawer.narrow > div:first-child {
	width: 80px !important;
}

.drawer.wide > div:first-child {
	width: 250px !important;
}

.drawer .menu-item-mini {
	width: 250px;
} */

.app-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 80px;
}

.dpl-logo {
	/* margin-top: 14px; */
	align-self: center;
}

.centered {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.header {
	background: linear-gradient(180deg, #474a5e, #292c39);
}

.sub-header {
	/*background-color: #331350;*/
	/* background-color: #1d2029; */
	box-shadow: 0px -2px 15px 2px rgba(0, 0, 0, 0.5);
	background-color: #292d3a;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	height: 36px;
	color: white;
	z-index: 2;
	/* padding-left: 80px; */
}

.user-profile {
	position: relative;
	padding: 20px 20px 20px 20px;
	/*margin-bottom: 8px;*/
}

.user-profile .background {
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	/* background: linear-gradient(180deg, #474a5e, #292c39); */
}

.user-profile .background img {
	width: 120%;
}

.user-profile > a {
	height: auto;
	padding: 0;
}

.user-profile .circle {
	height: 64px;
	width: 64px;
	display: block;
}

.user-profile .name {
	margin-top: 16px;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	display: block;
}

.user-profile .email {
	padding-bottom: 16px;
	font-weight: 300;
	font-size: 14px;
	line-height: 24px;
	display: block;
}

.white-text {
	color: #fff !important;
}

.breadcrumb-bar {
	/* padding: 0px 24px 0px 24px; */
	display: flex;
	flex: 1;
	height: 100%;
	align-items: center;
}

.breadcrumb-first {
	line-height: 22px;
	/* font-size: 13px; */
	font-size: 0.8em;
	/* font-weight: 300; */
	color: rgba(255, 255, 255, 0.7);
	text-transform: uppercase;
}

.breadcrumb {
	line-height: 22px;
	font-size: 0.8em;
	/* font-weight: 300; */
	color: rgba(255, 255, 255, 0.7);
	text-transform: uppercase;
}

.breadcrumb:last-child {
	color: #fff;
	font-weight: 600;
	font-size: 0.8em;
	text-transform: uppercase;
}

.breadcrumb:first-child:before {
	display: inline-block;
}

.breadcrumb:before {
	content: '\e7c3';
	font-family: 'Material-Design-Icons';
}

.breadcrumb:before {
	content: '\E5CC';
	color: rgba(255, 255, 255, 0.4);
	vertical-align: top;
	display: inline-block;
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 20px;
	margin: 0 8px 0 8px;
	-webkit-font-smoothing: antialiased;
}

/*.tabs {

}*/
/* 
.tabs .tab {
	color: #999 !important;
	transition: all 150ms cubic-bezier(0.29, 0.15, 0.07, 1.01);
}

.tabs .tab:hover {
	color: #fff !important;
	transition: all 150ms cubic-bezier(0.29, 0.15, 0.07, 1.01);
} */

/* a:hover {
  color: #ffcc00;
  text-decoration: none;
  transition: all 150ms cubic-bezier(0.29, 0.15, 0.07, 1.01);
} */
/* 
.tab.active {
	font-weight: 400 !important;
	color: #ffcc00 !important;
}

.tab.active:hover {
	color: #ffcc00 !important;
}

.tabs {
	width: auto;
	display: block;
} */

/* @media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }

  .tabs {
    width: 85%;
  }
} */

.menu-item.active {
	background-color: white !important;
	color: #ffcb01 !important;
	border-left: 3px solid #ffcb01 !important;
	padding-left: 7px !important;
}

a.menu-item {
	padding: 0px 0px 0px 10px !important;
}

.menu-item-mini.active {
	background-color: white !important;
	color: #ffcb01 !important;
	border-left: 3px solid #ffcb01 !important;
	padding-left: 7px !important;
}

a.menu-item-mini {
	padding: 0px 0px 0px 10px !important;
}

table {
	background-color: transparent !important;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* .container {
	display: flex;
	flex-direction: column;
} */

.messages-container {
	overflow-y: scroll;
	overflow-x: hidden;
	flex-grow: 1;
	padding: 20px;
}
/* 
h3 {
	text-align: center;
	padding: 20px 0;
	margin: 0;
	border-bottom: 1px solid #ddd;
	background-color: #eee;
} */

.chat-input {
	position: relative;
	overflow: hidden;
	padding: 0 40px;
	flex-shrink: 0;
}

.chat-input input[type='text'] {
	width: 100%;
	margin-left: -20px;
	margin-right: -20px;
}

.message.from-me .username {
	display: none;
}

.message.from-me {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 5px;
}

.message.from-me .message-body {
	background-color: #cdd8de;
	color: #000;
}

.message {
	margin-bottom: 20px;
}
.message-body {
	/* max-width: 80%; */
	display: inline-block;
	padding: 10px;
	/* background-color: #f0f2f4; */
	background-color: #eef2f6;
	border: 1px;
	border-radius: 5px;
	padding-right: 50px;
}

.username {
	font-weight: bold;
	font-size: 0.9rem;
	color: #999;
	margin-bottom: 5px;
}

nav {
	background-color: transparent;
	box-shadow: none;
}

.circle {
	overflow: hidden;
	border-radius: 50%;
}

.dot {
	display: inline-block;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	margin-right: 3px;
	background: rgba(0, 0, 0, 0.5);
	animation: wave 1.3s linear infinite;
}

.dot:nth-child(2) {
	animation-delay: -1.1s;
}

.dot:nth-child(3) {
	animation-delay: -0.9s;
}

@keyframes wave {
	0%,
	60%,
	100% {
		transform: initial;
	}

	30% {
		transform: translateY(-10px);
	}
}

.text-danger {
	color: #f44336;
}

.spacer {
	margin-top: 5px;
	margin-bottom: 5px;
}

.info-table {
	border-spacing: 0;
	line-height: 1.8em;
	color: rgba(0, 0, 0, 0.87);
	font-size: 0.875rem;
	font-weight: 400;
	font-family: 'din_lightregular', 'Roboto', sans-serif;
}

.info-table td:first-child {
	width: 160px;
}

.info-table td {
	/* min-width: 100px;
	max-width: 200px; */
	vertical-align: top;
	padding-right: 20px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid #ececec;
}

.info-table td + td {
	padding-left: 20px;
}

.live-badge {
	padding: 4px 8px;
	background-color: red;
	border-radius: 6px;
	color: white;
	font-weight: 700;
	font-size: 0.8em;
}

.completed-badge {
	padding: 4px 8px;
	background-color: #ddd;
	border-radius: 6px;
	/* color: white; */
	font-weight: 700;
	font-size: 0.8em;
}

.match-badge {
	padding: 4px 8px;
	font-weight: 600;
	font-size: 0.8em;
}

.match-badge + .match-badge {
	margin-left: 1px;
}

.match-badge.win {
	background-color: rgb(41, 45, 58);
	color: white;
}

.match-badge.loss {
	background-color: red;
	color: white;
}

.truncate {
	white-space: nowrap;
	width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
}
