.podium {
	display: flex;
	height: 170px;
}

.podium .place {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.podium .place + .place {
	margin-left: 1px;
}

.podium .place .participant {
	text-align: center;
	width: 100%;
	flex: 1;
	align-items: center;
	display: flex;
	justify-content: center;
	font-size: 16px;
}

.podium .place .participant .info {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.podium .place .base {
	background-color: black;
	vertical-align: bottom;
	position: relative;
	display: inline-block;
	width: 100%;
}

.podium .place .number {
	padding: 10px 0px;
	position: relative;
}

.podium .place.position1 .base {
	height: 50px;
	background-color: #ffcc00;
	background: linear-gradient(#fff0b3, #ffcc00);
}

.podium .place.position1 .number {
	color: #fdca3a;
}

.podium .place.position2 .base {
	height: 25px;
	background-color: #b0bec5;
	background: linear-gradient(#dee1e2, #b0bec5);
}

.podium .place.position2 .participant .info {
	margin-top: 10px;
}

.podium .place.position3 .base {
	height: 10px;
	background-color: #ff9800;
	background: linear-gradient(#ffcd83, #ff9800);
}

.podium .place.position3 .participant .info {
	margin-top: 30px;
}
