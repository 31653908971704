.pool-table {
	margin-top: 0.5em;
	position: relative;
	margin: auto;
	/* width: 100%; */
	/* width: 350px; */
	/* padding: 22px; */
	/* min-height: 160px; */
	/* height: 190px; */
	transition: opacity 300ms ease;
	display: flex;
	flex-direction: column;
	/* align-items: stretch; */
	/* align-content: center; */
	justify-content: center;
}

.pool-table img {
	position: relative;
	z-index: 1;
	top: 0px;
	left: 0px;
	/* width: 100%; */
	/* height: 100%; */
	max-width: 100%;
	max-height: 100%;
}

.pool-table > a {
	opacity: 1;
	transition: opacity 300ms ease;
}

.pool-table .ended {
	opacity: 0.7;
}

.pool-table .player-info,
.team-info {
	margin-left: 10px;
	color: white;
	font-weight: 300;
	display: inline-block;
	vertical-align: middle;
	line-height: 1.4em;
	font-family: 'din_medium', 'Roboto', sans-serif;
}

.pool-table .match-info {
	width: 100%;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 2;
	display: flex;
	flex-direction: column;
}

.pool-table .match-link {
	display: flex;
	align-items: center;
}

.pool-table .item {
	width: 100%;
	padding: 0.2em 2.2em;
	overflow: hidden;
	border-radius: 5px;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: space-between;
}

.pool-table .item + .item {
	margin-top: 5px;
}

.pool-table .item.winning {
	background-color: rgba(0, 0, 0, 0.25);
}

.pool-table .item.winner {
	background-color: rgba(0, 0, 0, 0.25);
	/* background-color: #999FB3; */
}

.pool-table .item .content {
	width: 100%;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: flex-start;
}

.pool-table .item .content .ui.avatar.image {
	margin-right: 1em;
}

.pool-table .item .score {
	margin: 5px;
	min-width: 50px;
	font-size: 1.5em;
	font-weight: 600;
	color: white;
	white-space: nowrap;
	padding: 7px;
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 5px;
	text-align: center;
	font-family: 'din_medium', 'Roboto', sans-serif;
}
