/* @import url("../fonts/din/stylesheet.css");
@import url("materialize.min.css");
@import url("animate.min.css"); */

button:focus {
	outline: none;
	background-color: rgba(0, 0, 0, 0.1);
	border: none;
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px; /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';
}

.draw {
	display: flex;
}

.a-side {
	display: flex;
	/* width: 100%; */
}

.b-side {
	display: flex;
	/* width: 100%; */
	order: -1;
}

/* .losers .match-spacer {
	border-left: 1px solid #aaa;
}

.winners .match-spacer {
	border-right: 1px solid #aaa;
} */

/* .losers .round:nth-child(n + 1) {
	order: -1;
} */

/* .round .spacer {
	flex-grow: 0.5;
} */

/* .losers .round:nth-child(n + 2) .spacer {
	flex-grow: 0.5;
} */

.connector {
	/* flex-shrink: 0; */
	/* flex: 1; */
	align-items: center;
	/* height: 100%; */
	display: flex;
	/* margin-top: 66px; */
	padding: 32px 0px;
}

.connector > div {
	display: inline-block;
	vertical-align: middle;
}

.connector .merger {
	position: relative;
	height: 100%;
	box-sizing: border-box;
	width: 2rem;
	display: inline-block;
	vertical-align: top;
	border-top: 1px solid #c0c0c8;
	border-bottom: 1px solid #c0c0c8;
	min-height: 66px;
}

.connector.left .merger {
	border-left: 1px solid #c0c0c8;
}

.connector.right .merger {
	border-right: 1px solid #c0c0c8;
}
/* 
.connector .merger:before {
	border-right-width: thin;
	border-top-width: thin;

	content: '';
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 50%;
	border: 0 solid;
	border-color: #c0c0c8;
}

.connector .merger:after {
	border-right-width: thin;
	border-bottom-width: thin;

	content: '';
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 50%;
	border: 0 solid;
	border-color: #c0c0c8;
} */

.connector .line {
	border-bottom: thin solid #c0c0c8;
	/* height: 50%; */
	box-sizing: border-box;
	width: 2rem;
	display: inline-block;
	vertical-align: top;
	flex: 1;
	align-items: center;
}

.rounds {
	display: flex;
}

.round {
	display: flex;
	flex-direction: column;
	list-style: none;
	padding: 0 0 40px 0;
	min-width: 300px;
	/* transition: all ease 150ms; */
	/* width: 100%; */
}
/* 
.round:hover {
	background-color: rgba(255, 255, 255, 0.5);
} */

.round-header {
	text-align: center;
	padding: 10px;
	background-color: #fff !important;
	/* min-width: 300px; */
	border-bottom: 1px solid #ededed;
	border-right: 1px solid #ededed;
	margin-bottom: 40px;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

/* .round > div:nth-child(n + 2) {
	margin: 0 10px;
} */

/* .round .spacer:nth-child(n + 2) {
	flex-grow: 1;
	text-align: center;
} */

.round .match-spacer {
	flex-grow: 1;
	padding: 1em 0em;
	text-align: center;
	/* min-height: 40px; */
	min-height: 66px;
	align-items: center;
	display: flex;
	justify-content: center;
}

.draw .round .match-spacer {
	border-left: 2px solid #888888;
	border-right: 2px solid #888888;
}

.a-side .round .match-spacer {
	border-right: 2px solid #888888;
}

.b-side .round .match-spacer {
	border-left: 2px solid #888888;
	/* border-left: 2px solid #c0c0c8; */
}

.no-connector {
	border: none !important;
}

.round .spacer {
	flex-grow: 0.5;
	text-align: center;
}

.matches {
	/* display: flex; */
	/* flex: 1; */
	/* flex-direction: column;
	left: -1px;
	position: relative;
	width: 100%; */
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	justify-content: space-between;
}

.match-group {
	display: flex;
	flex-direction: row;
	/* align-items: center; */
	flex: 1;
	flex-grow: 1;
}

.match-group > div {
	/* display: inline-block; */
	/* vertical-align: middle; */
}

/* .match:first-child {
	margin-bottom: 10rem;
} */

.match {
	/* margin-top: -23px; */
	margin: 0 10px;
	position: relative;
}

.match.bottom {
	/* margin-top: -5px; */
	margin-top: -9px;
}

.a-side .match:not(.top):not(.bottom):before {
	border-left-style: solid;
	border-top-style: solid;
	height: 50%;
	top: 50%;
}

.b-side .match:not(.top):not(.bottom):after {
	border-right-style: solid;
	border-top-style: solid;
	height: 50%;
	top: 50%;
}

.match:before {
	content: '';
	border-color: #888888;
	border-width: 2px;
	position: absolute;
	height: 2px;
	width: 10px;
	left: -12px;
	/* top: calc(50% + 10px); */
}

.draw .match:before {
	left: -10px;
}

.a-side .match:before {
	left: -12px;
}

.b-side .match:before {
	left: -10px;
}

.match.top.no-connectors:before {
	height: auto;
}

.match.bottom.no-connectors:before {
	height: auto !important;
	top: 50% !important;
}

.match.top.no-right-connector:after {
	height: auto;
}

.match.bottom.no-right-connector:after {
	height: auto;
}

.match.top:before {
	border-left-style: solid;
	border-top-style: solid;
	height: 50%;
	top: calc(50%);
}

.match.bottom:before {
	border-left-style: solid;
	border-bottom-style: solid;
	height: 50%;
	top: 2px;
	/* top: calc(50% - 32px); */
}

.match:after {
	content: '';
	border-color: #888888;
	border-width: 2px;
	position: absolute;
	display: block;
	width: 10px;
	right: -10px;
}

.draw .match:before {
	right: -12px;
}

.a-side .match:after {
	right: -10px;
}

.b-side .match:after {
	right: -12px;
}

.match.top:after {
	border-right-style: solid;
	border-top-style: solid;
	height: 50%;
	top: 50%;
}

.match.bottom:after {
	border-right-style: solid;
	border-bottom-style: solid;
	height: 50%;
	top: calc(50% - 53px);
}

.b-side .match.bottom:after {
	border-right-style: solid;
	border-bottom-style: solid;
	height: auto;
	top: 50%;
}

.b-side .match.bottom:before {
	border-left-style: solid;
	border-bottom-style: solid;
	height: 50%;
	top: 2px;
}

/* .match:last-child:after {
	transform: translateY(-100%);
}

.match:before {
	content: '';
	position: absolute;
	background-color: #fff;
	right: 0;
	top: 50%;
	transform: translateX(100%);
	width: 25px;
	height: 2px;
}

.match:after {
	content: '';
	position: absolute;
	background-color: #fff;
	right: -25px;
	height: calc(50% + 22px);
	width: 2px;
	top: 50%;
} */

.time {
	font-size: 12px;
	padding-left: 5px;
	padding-bottom: 5px;
	color: #aaa;
}

.live .match-data {
	background-color: red;
}

.live .match-number {
	color: white;
}

.live-text {
	font-size: 12px;
	padding-left: 5px;
	padding-bottom: 5px;
	color: red;
}

.completed .match-data {
	/* background-color: #1d212a; */
}

.completed .match-number {
	/* color: white; */
}

.match-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.match-data {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #bac2cc;
	border-radius: 8px;
	overflow: hidden;
}

.match-footer {
	margin: 2px 0 0 0;
	font-size: 12px;
	/* color: #aaa; */
	min-height: 18px;
}

.match-number {
	padding: 10px;
}

.match-scores {
	flex: 1;
	background-color: #fff;
}

.player {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid rgb(237, 237, 237);
	cursor: pointer;
	transition: all 150ms cubic-bezier(0.29, 0.15, 0.07, 1.01);
}

.player.winner:hover {
	background-color: #fff;
}

.player.loser:hover {
	background-color: #fff;
}

.player-number {
	align-items: center;
	/* background-color: #fafafa; */
	border-right: 1px solid rgb(237, 237, 237);
	padding: 5px 10px 5px 10px;
	min-width: 35px;
	text-align: center;
}

.player-name {
	align-items: center;
	flex: 1;
	text-align: left;
	padding: 5px 10px 5px 10px;
	display: flex;
}

.player-name .flag {
	margin-right: 10px;
}

.player-score {
	align-items: center;
	font-weight: 600;
	padding: 5px 10px 5px 10px;
	min-width: 35px;
	text-align: center;
	font-size: 1.1em;
}

.player.winner {
}

.player.loser {
	/* opacity: 0.5; */
	/* background-color: #eff3f5; */
	background-color: #eaedef;
}

.player.winner .player-score {
	/* background-color: orange; */
	background-color: #ffcd01;
}

.player.loser .player-score {
	/* background-color: #ffcd01; */
}

/* .match {
	font-size: 1.3em;
	display: flex;
	height: 30px;
	align-items: center;
	align-content: center;
	justify-content: space-between;
	white-space: nowrap;
	min-width: 200px;
} */
